
/**
 * Alerte avec message d'information.
 *
 * @param   {String}   message
 * @param   {function} callback
 * @returns {undefined}
 */
BootstrapDialog.info = function(message, callback)
{
    return new BootstrapDialog({
        type: BootstrapDialog.TYPE_INFO,
        title: "Information",
        message: message,
        closable: false,
        data: {
            'callback': callback
        },
        buttons: [
            {
                label: 'OK',
                action: function(dialog) {
                    typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(true);
                    dialog.close();
                }
            }
        ]
    }).open();
};

/**
 * Alerte avec message de confirmation.
 *
 * @param   {String}   message
 * @param   {function} callback
 * @returns {undefined}
 */
BootstrapDialog.confirm = function(message, callback)
{
    return new BootstrapDialog({
        type: BootstrapDialog.TYPE_DEFAULT,
        title: "Confirmation",
        message: message,
        closable: false,
        data: {
            'callback': callback
        },
        buttons: [
            {
                label: '<i class="fa fa-check"></i> Oui',
                cssClass: 'btn-danger',
                action: function(dialog) {
                    typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(true);
                    dialog.close();
                }
            }, {
                label: '<i class="fa fa-times"></i> Non',
                cssClass: 'btn-success',
                action: function(dialog) {
                    typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(false);
                    dialog.close();
                }
            }
        ]
    }).open();
};

/**
 * Alerte avec message d'avertissement.
 *
 * @param   {String}   message
 * @param   {function} callback
 * @returns {undefined}
 */
BootstrapDialog.warning = function(message, callback)
{
    return new BootstrapDialog({
        type: BootstrapDialog.TYPE_WARNING,
        title: "Attention !",
        message: message,
        closable: false,
        data: {
            'callback': callback
        },
        buttons: [
            {
                label: 'OK',
                action: function(dialog) {
                    typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(true);
                    dialog.close();
                }
            }
        ]
    }).open();
};

/**
 * Alerte avec message d'erreur.
 *
 * @param   {String}   message
 * @param   {function} callback
 * @returns {undefined}
 */
BootstrapDialog.danger = function(message, callback)
{
    return new BootstrapDialog({
        type: BootstrapDialog.TYPE_DANGER,
        title: "Erreur...",
        message: message,
        closable: false,
        data: {
            'callback': callback
        },
        buttons: [
            {
                label: 'OK',
                action: function(dialog) {
                    typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(true);
                    dialog.close();
                }
            }
        ]
    }).open();
};
