import Swal from 'sweetalert2';

export const SwallToastError = Swal.mixin({
    toast: true,
    position: 'top',
    timerProgressBar: true,
    timer: 10000,
    width: '35em',
    showConfirmButton: false,
    icon: 'error',
    showClass: {
        popup: 'animate__animated animate__bounceInDown'
    },
    hideClass: {
        popup: 'animate__animated animate__bounceOut'
    },
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    buttonsStyling: false,
    customClass: {
        timerProgressBar: 'bg-danger',
        confirmButton: 'btn btn-danger',
        htmlContainer: 'text-danger'
    }
})
