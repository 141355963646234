
window._ = require('lodash');
window.Popper = require('popper.js').default;

// jQuery
window.$ = window.jQuery = require('jquery');
require('./setup/jquery-ajax.js');

// Bootstrap
window.bootstrap = require('bootstrap');

// Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// Bootstrap Dialog
window.BootstrapDialog = require('bootstrap4-dialog/dist/js/bootstrap-dialog.js');
require('./setup/bootstrap-dialog.js');

// Sweetalert2
import Swal from 'sweetalert2'
window.Swal = Swal;

// fslightbox
require('fslightbox');

// Validation de formulaire via AJAX
require('./plugins/jquery.validate.js');
