import { SwallToastError } from './../setup/swal-toast-error.js';

(function($) {

    $.fn.validate = function(onSuccess) {

        if (this.length > 1) {
            this.each(function() {
                $(this).validate(onSuccess);
            });

            return this;
        }

        var $form = $(this);
        var formId = $(this).attr('id');
        var $submitBtn = $form.find('button[type=submit]');

        return $form.on('submit', (e) => {
            e.preventDefault();

            $submitBtn.css('cursor', 'wait').prop('disabled', true);
            $('.form-error').remove();

            $('.ck-editor__editable').each(function() {
                this.ckeditorInstance.updateSourceElement();
            });

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: $form.serialize(),
                success(responseText) {
                    if (onSuccess) {
                        onSuccess(responseText, $submitBtn);
                    } else {
                        location.href = responseText;
                    }
                },
                error(xhr) {
                    if (xhr.status == 422) {
                        SwallToastError.fire({
                            text: 'Des informations sont manquantes ou invalides. Merci de vérifier votre saisie.'
                        });

                        var index = 0;

                        // Affichage des messages d'erreurs de validation
                        $.each(xhr.responseJSON.errors, (fieldKey, message) => {
                            var $field = $('#'+formId+'--'+fieldKey.replace(/\.|_/g, '-'));

                            if (index == 0) {
                                $field.focus();
                            }

                            var $fieldContainer = $field.parent();

                            if ($fieldContainer.hasClass('input-group')) {
                                $fieldContainer = $fieldContainer.parent();
                            }

                            $fieldContainer.append(
                                '<div class="form-error"><i class="fa fa-times"></i> '+message+'</div>'
                            );

                            index++;
                        });
                    } else {
                        SwallToastError.fire({
                            text: xhr.responseJSON.message ?? 'Une erreur est survenue, merci de réessayer ultérieurement.'
                        });
                    }

                    $submitBtn.css('cursor', 'pointer').prop('disabled', false);
                }
            });
        });
    };

})(jQuery);
