require('./bootstrap');

(function($) {

    var $modalAddToCart = $('#modal-add-to-cart');
    var $cartCount = $('.cart-count');

    $('.form-add-to-cart').validate((responseText, $submitBtn) => {
        $modalAddToCart.find('.modal-body').html(responseText);
        $modalAddToCart.modal('show');

        $submitBtn.prop('disabled', false).css('cursor', 'pointer');

        $cartCount.html(xhr.getResponseHeader('cart_count'));
        $cartCount.removeClass('badge-secondary').addClass('badge-warning');
    });

})(jQuery);
