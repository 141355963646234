import { SwallToastError } from './swal-toast-error.js';

(function($) {

    var httpErrors = {
        401: {
            title: "Authentification requise",
            message: "Désolé, vous devez être authentifié pour accéder à cette ressource."
        },
        403: {
            title: "Accès refusé",
            message: "Désolé, vous ne disposez pas des permissions nécessaires pour accéder à cette ressource."
        },
        404: {
            title: "Page non trouvée",
            message: "Le serveur n’a rien trouvé qui corresponde à l’adresse (URI) demandée."
        },
        475: {
            title: "Erreur jeton d’identification",
            message: "Le jeton d’identification de votre session a expiré."
        },
        500: {
            title: "Erreur interne du serveur",
            message: "Le serveur HTTP a rencontré une condition inattendue qui l’a empéché de traiter la requête."
        },
        503: {
            title: "Maintenance en cours",
            message: "Merci de réessayer ultérieurement."
        }
    };

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        error: function(jqXHR, textStatus) {
            var code = parseInt(jqXHR.status);

            // Erreurs de validation
            if (code === 422) {
                SwallToastError.fire({
                    title: 'Informations saisies incorrectes :',
                    html: '<ul><li>'+$.parseJSON(jqXHR.responseText).join('</li><li>')+'</li></ul>'
                })
            }
            // Erreur HTTP
            else if (httpErrors[code]) {
                SwallToastError.fire({
                    title: httpErrors[code].title+' ('+code+')',
                    text: httpErrors[code].message
                })
            }
            // Erreur d'analyse de la réponse
            else if (textStatus === 'parsererror') {
                SwallToastError.fire({
                    text: 'Erreur lors de l’analyse de la réponse JSON.'
                })
            }
            // Timeout
            else if (textStatus === 'timeout') {
                SwallToastError.fire({
                    text: 'Le serveur met trop de temps à répondre.'
                })
            }
        }
    });

})(jQuery);
